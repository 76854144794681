<template>
  <div>
    <div v-if="surveyDetail && !isEmpty" id="divTakeSurveyWrapper" class="take-survey-wrapper">
      <div class="survey-title">
        <div class="content-layout-fixer">
          <div style="margin-left: 20px">{{ title }}</div>
        </div>
      </div>
      <div class="content-layout-fixer">
        <VueWrapperNarrow>
          <div class="questions-wrapper">
            <div
              v-for="(q, i) in surveyDetail.questions"
              :key="q.questionId"
              class="question-container"
            >
              <div class="question-container-question">
                <div>
                  <VueText v-if="q.link" sizeLevel="6" weightLevel="2">
                    {{
                      `${surveyDetail.questions.length > 1 ? i + 1 + '. ' : ''}
                    ${parseQuestionLink(q).before}`
                    }}
                    <a :href="q.link" target="_blank">{{ `${q.wordToLink}` }}</a>
                    {{
                      `${surveyDetail.questions.length > 1 ? i + 1 + '. ' : ''}
                    ${parseQuestionLink(q).after}`
                    }}
                  </VueText>
                  <VueText v-else sizeLevel="6" weightLevel="2">{{
                    `${surveyDetail.questions.length > 1 ? i + 1 + '. ' : ''}${q.question}`
                  }}</VueText>
                  <VueText :color="'red-30'" sizeLevel="4" weightLevel="2" v-if="q.isRequired"
                    >* Zorunlu Seçenek</VueText
                  >
                </div>
                <VueText
                  sizeLevel="4"
                  weightLevel="2"
                  class="dropdownNumericUnitPrice"
                  :color="'white-100'"
                  v-if="q.questionStyle === 5 && q.dropdownQuestionDetail.isNumeric"
                >
                  Birim Fiyatı: {{ `${q.dropdownQuestionDetail.unitPrice}` }} TL
                </VueText>
              </div>
              <div v-if="q.image || q.embedVideo" class="question-container-media">
                <BrandMediaContentBox
                  v-if="q.questionType == 2 && q.image"
                  :mediaSrc="q.image"
                  :aspectRatios="constants.mediaRatio"
                >
                </BrandMediaContentBox>
                <BrandVimeoPlayer
                  v-if="q.questionType == 3 && q.embedVideo"
                  :data="q"
                ></BrandVimeoPlayer>
              </div>

              <!-- Standart Question Type -->
              <div v-if="q.questionStyle === 1" class="question-container-options">
                <VueListView dir="column" class="option-list">
                  <VueButton
                    class="option"
                    v-for="op in q.options"
                    :key="op.optionId"
                    :size="sizes.xLarge"
                    :isSingleLine="false"
                    :disabled="finish"
                    :class="optionClass(op.optionId)"
                    :contentAlignment="constants.flexAlignment.start"
                    @click="selectedAnswers(q, q.isMultiChoise, op.optionId, null, null, op)"
                    outlined
                    >{{ op.option }}</VueButton
                  >
                  <textarea
                    v-if="q.isOpenText"
                    @input="selectedAnswers(q)"
                    placeholder="Cevabınızı yazınız..."
                    class="option"
                    rows="3"
                  />
                  <textarea
                    :id="'option' + q.index"
                    @input="selectedAnswers(q)"
                    placeholder="Cevabınızı yazınız..."
                    class="option"
                    rows="3"
                    hidden
                  />
                </VueListView>
              </div>
              <!-- EVALUATION Question Type -->
              <div v-if="q.questionStyle === 2">
                <div>
                  <ul class="question-vote">
                    <li
                      v-for="(vote, i) in filteredVote(q)"
                      :key="i"
                      :class="[
                        { active: isVoteActive(vote.value) },
                        'vote-item',
                        `vote-item-${q.questionId}`,
                      ]"
                      :data-id="`vote-${vote.value + '-' + q.questionId}`"
                    >
                      <a
                        :class="vote.voteClass"
                        @click="pointVote(vote.value, q.questionId), selectVote(vote.value, q)"
                        >{{ vote.value }}</a
                      >
                    </li>
                  </ul>
                  <!-- Sub question -->
                  <div
                    v-if="subQuestions.find(i => i.mainQuestionId === q.questionId)"
                    style="margin-left: 10px"
                  >
                    <div class="question-container-question">
                      {{ subQuestions.find(i => i.mainQuestionId === q.questionId).question }}
                    </div>
                    <div class="question-container-options">
                      <VueListView dir="column" class="option-list">
                        <VueButton
                          class="option"
                          v-for="op in subQuestions.find(i => i.mainQuestionId === q.questionId)
                            .options"
                          :key="op.optionId"
                          :size="sizes.xLarge"
                          :isSingleLine="false"
                          :disabled="finish"
                          :class="optionClass(op.optionId, true)"
                          :contentAlignment="constants.flexAlignment.start"
                          @click="
                            selectedAnswers(
                              q,
                              subQuestions.find(i => i.mainQuestionId === q.questionId)
                                .isMultiChoise,
                              op.optionId,
                              subQuestions.find(i => i.mainQuestionId === q.questionId).questionId,
                            )
                          "
                          outlined
                          >{{ op.option }}</VueButton
                        >
                        <textarea
                          v-if="
                            subQuestions.find(i => i.mainQuestionId === q.questionId).isOpenText
                          "
                          @input="
                            selectedAnswers(
                              q,
                              q.isMultiChoise,
                              null,
                              subQuestions.find(i => i.mainQuestionId === q.questionId).questionId,
                            )
                          "
                          placeholder="Cevabınızı yazınız..."
                          class="option"
                          rows="3"
                        ></textarea>
                      </VueListView>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Time Question Type -->
              <template v-if="q.questionStyle === 3">
                <div v-if="q.dateAnswerType === 1">
                  <datepicker
                    wrapper-class="date-wrapper"
                    input-class="date-input"
                    placeholder="Seçiniz"
                    format="dd/MM/yyyy"
                    @selected="selectDateAndTime($event, q.questionId, 'date', q)"
                  />
                </div>
                <div v-if="q.dateAnswerType === 2">
                  <vue-timepicker
                    class="time-picker"
                    placeholder="Seçiniz"
                    close-on-complete
                    hide-clear-button
                    hide-dropdown
                    hour-label="Saat"
                    minute-label="Dakika"
                    @change="selectDateAndTime($event, q.questionId, 'hour', q)"
                  ></vue-timepicker>
                </div>
                <div v-if="q.dateAnswerType === 3" class="picker-wrapper">
                  <datepicker
                    wrapper-class="date-wrapper"
                    input-class="date-input"
                    placeholder="Seçiniz"
                    format="dd/MM/yyyy"
                    @selected="selectDateAndTime($event, q.questionId, 'date', true, q)"
                    :language="tr"
                  ></datepicker>
                  <vue-timepicker
                    class="time-picker"
                    placeholder="Seçiniz"
                    close-on-complete
                    hide-clear-button
                    hide-dropdown
                    hour-label="Saat"
                    minute-label="Dakika"
                    @change="selectDateAndTime($event, q.questionId, 'hour', true, q)"
                  ></vue-timepicker>
                </div>
              </template>
              <!-- IF-ELSE Question Type -->
              <div
                v-if="q.questionStyle === 4 && q.options.length"
                class="question-container-options"
              >
                <VueListView dir="column" class="option-list">
                  <VueButton
                    class="option"
                    v-for="op in q.options"
                    :key="op.optionId"
                    :size="sizes.xLarge"
                    :isSingleLine="false"
                    :disabled="finish"
                    :class="optionClass(op.optionId)"
                    :contentAlignment="constants.flexAlignment.between"
                    @click="selectedAnswers(q, q.isMultiChoise, op.optionId, null, op.option)"
                    outlined
                    >{{ op.option }}</VueButton
                  >
                </VueListView>
                <!-- Sub question -->
                <div
                  v-if="ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId)"
                  style="margin-left: 10px"
                >
                  <div class="question-container-question">
                    {{ ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId).question }}
                  </div>
                  <div class="question-container-options">
                    <VueListView
                      dir="column"
                      class="option-list"
                      v-if="ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId).options"
                    >
                      <span hidden></span>
                      <VueButton
                        class="option"
                        v-for="op in ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId)
                          .options"
                        :key="op.optionId"
                        :size="sizes.xLarge"
                        :isSingleLine="false"
                        :disabled="finish"
                        :class="optionClass(op.optionId, true, true)"
                        :contentAlignment="constants.flexAlignment.start"
                        @click="
                          selectedAnswers(
                            q,
                            ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId)
                              .isMultiChoise,
                            op.optionId,
                            ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId)
                              .questionId,
                          )
                        "
                        outlined
                        >{{ op.option }}</VueButton
                      >
                      <textarea
                        v-if="
                          ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId).isOpenText
                        "
                        @input="
                          selectedAnswers(
                            q,
                            q.isMultiChoise,
                            null,
                            ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId)
                              .questionId,
                          )
                        "
                        placeholder="Cevabınızı yazınız..."
                        class="option"
                        rows="3"
                      ></textarea>
                    </VueListView>
                  </div>
                </div>
              </div>
              <!-- DROPDOWN Question Type -->
              <template v-if="q.questionStyle === 5" class="question-container-question">
                <div class="question-container-options">
                  <div class="option-dropdown">
                    <select :disabled="finish" @change="onDropdownOptionChange(q, $event)">
                      <option selected value="-1" disabled>Seçiniz</option>
                      <option
                        :data-order="op.orderNo"
                        :value="op.optionId"
                        v-for="op in q.options"
                        :key="op.optionId"
                      >
                        {{ op.option }}
                      </option>
                    </select>
                  </div>
                  <VueText
                    sizeLevel="6"
                    weightLevel="2"
                    v-if="q.dropdownQuestionDetail.isNumeric"
                    class="dropdownPrice"
                    >Ürün Tutarı:
                    {{
                      dropdownSelections[i]
                        ? dropdownSelections[i] * q.dropdownQuestionDetail.unitPrice
                        : 0
                    }}
                    TL</VueText
                  >
                </div>
              </template>
            </div>
            <div class="dropdownNumericPriceTotalContainer">
              <VueText
                sizeLevel="6"
                weightLevel="2"
                class="dropdownNumericPriceTotal"
                :color="'white-100'"
                v-if="dropdownNumericPrices.length"
                >Genel Toplam: {{ totalDropdownPrice }} TL</VueText
              >
            </div>
          </div>
          <BrandButton
            v-if="finish"
            class="take-survey-wrapper-finish"
            :size="sizes.xxLarge"
            :disabled="!validate"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            @click="goToHome()"
            >BİTİR</BrandButton
          >
          <BrandButton
            v-else
            class="take-survey-wrapper-finish"
            :size="sizes.xxLarge"
            :disabled="!validate || finish"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            @click="saveAnswers()"
            >{{ validate === true ? 'BİTİR' : buttonText }}</BrandButton
          >
          <BrandModal></BrandModal>
        </VueWrapperNarrow>
      </div>
    </div>
    <div v-else-if="isEmpty" class="empty-container">
      <img src="~@/assets/icons/checkGradient.svg" alt="check" />
      <VueText sizeLevel="11" weightLevel="3">{{ errorMessage }}</VueText>
      <BrandButton
        class="submit-button"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        @click.prevent="goToHome()"
        >Anasayfaya Dön</BrandButton
      >
    </div>
  </div>
</template>
<script>
import GameApi from '@/services/Api/gamification';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Datepicker from 'vuejs-datepicker';
import { tr } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'TakeSurvey',
  mixins: [StorageProps, gtmUtils],
  components: {
    VueButton,
    VueText,
    VueListView,
    BrandMediaContentBox,
    BrandVimeoPlayer,
    BrandButton,
    BrandModal,
    VueWrapperNarrow,
    VueTimepicker,
    Datepicker,
  },
  data() {
    return {
      dropdownSelections: [],
      dropdownNumericPrices: [],
      surveyDetail: null,
      otherOptionValue: null,
      formModel: {},
      selected: [],
      validate: false,
      finish: false,
      surveyFinished: false,
      rightOptions: [],
      title: null,
      isEmpty: null,
      errorMessage: '',
      tr: tr,
      subQuestions: [],
      ifElseSubQuestions: [],
    };
  },
  beforeMount() {
    GameApi.getSurveyDetail(this.$route.params.id)
      .then(res => {
        this.surveyDetail = res.data.Data.surveyDetail;
        this.surveyDetail.questions.forEach((element, index, arr) => {
          element.options.forEach(f => {
            if (f?.optionId == 0 && f?.option == 'Diğer') {
              f.optionId = Math.floor(Math.random() * 100) + 1000000000;
            }
          });

          arr[index] = Object.assign({}, arr[index], {
            answerSubmitted: false,
            index: index,
          });
        });
        if (this.surveyDetail.surveyType === 2) {
          this.title = 'Anket';
          this.pushDataLayerEvent('survey', {
            name: this.surveyDetail.surveyName,
          });
        } else {
          this.title = this.surveyDetail.surveyName;
        }
      })
      .catch(err => {
        if (err.response.data.Error && err.response.data.Error.Display === 2) {
          this.isEmpty = true;
          this.errorMessage = err.response.data.Error.Message;
        }
      });
  },
  computed: {
    totalDropdownPrice() {
      return this.dropdownNumericPrices.reduce((a, b) => a + b, 0);
    },
    buttonText() {
      const questions = this.surveyDetail.questions;
      if (questions.length > 1) {
        return 'SORULARI CEVAPLA';
      } else {
        return 'SORUYU CEVAPLA';
      }
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
        mediaRatio: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_16_9,
        icons: ICON_VARIABLES,
      };
    },
  },
  methods: {
    onDropdownOptionChange(question, event) {
      let order = event.target.selectedOptions[0].getAttribute('data-order');
      let value = parseInt(event.target.value);
      let unitPrice = parseFloat(question.dropdownQuestionDetail.unitPrice);
      this.$set(this.dropdownSelections, question.index, order);
      if (question.dropdownQuestionDetail.isNumeric) {
        this.$set(this.dropdownNumericPrices, question.index, parseInt(order) * unitPrice);
      }
      this.selectedAnswers(question, question.isMultiChoise, value, null, null, null);
    },
    onChangedOtherOptionValue(q) {
      this.otherOptionValue = document.getElementById('option' + q.index);
    },
    parseQuestionLink(q) {
      let wordLinkIndex = q.question.search(q.wordToLink);
      let wordLinkLength = q.wordToLink.length;
      let stringBeforeLink = q.question.substring(0, wordLinkIndex);
      let stringAfterLink = q.question.substring(wordLinkIndex + wordLinkLength);
      return {
        before: stringBeforeLink,
        after: stringAfterLink,
      };
    },
    selectedAnswers(q, isMultiChoise, optionId, subQuestionId, optionName, opt) {
      let selectedOption;
      let currentOptions = [];
      let otherOptionId = null;
      let element = document.getElementById('option' + q.index);

      q.options.forEach(f => {
        if (this.selected.map(m => m.optionId).includes(f.optionId)) {
          currentOptions.push(f);
        }
      });
      if (optionId) {
        if (isMultiChoise) {
          if (!subQuestionId) {
            const someOptionId = this.selected.filter(item => item.optionId === optionId)[0];
            const foundItem = this.surveyDetail.questions.filter(
              item => item.questionId === q.questionId,
            );
            if (!someOptionId) {
              foundItem[0].answerSubmitted = true;
              selectedOption = { questionId: q.questionId, optionId };
              this.selected.push({ ...selectedOption });
            } else {
              this.selected = this.selected.filter(item => item.optionId !== optionId);
              const someQuestionId = this.selected.filter(item => item.questionId === q.questionId);
              foundItem[0].answerSubmitted = someQuestionId.length > 0;
            }
          } else {
            const someOptionId = this.selected.filter(item =>
              item.evulationStyleSubQuestionAnswer?.options?.includes(optionId),
            )[0];
            if (!someOptionId) {
              const foundItem = this.subQuestions.filter(item => item.questionId === subQuestionId);
              foundItem[0].answerSubmitted = true;
              selectedOption = {
                questionId: q.questionId,
                evulationStyleSubQuestionAnswer: { subQuestionId, options: [optionId] },
              };
              this.selected.push({ ...selectedOption });
            } else {
              this.selected = this.selected.filter(
                item => !item.evulationStyleSubQuestionAnswer?.options.includes(optionId),
              );
            }
          }
        } else {
          if (!subQuestionId) {
            if (q.questionStyle === 4) {
              let c = this.ifElseSubQuestions.find(i => i.mainQuestionId === q.questionId);
              if (c) {
                this.selected = this.selected.filter(
                  i => i.ifElseStyleSubQuestionAnswer?.subQuestionId !== c.questionId,
                );
                c.answerSubmitted = false;
              }
              let sq =
                optionName === 'EVET'
                  ? q.ifElseQuestionDetail?.subQuestions.find(i => i.isIfAnswer)
                  : q.ifElseQuestionDetail?.subQuestions.find(i => !i.isIfAnswer);
              if (sq) {
                sq.answerSubmitted = false;
                let index = this.ifElseSubQuestions.findIndex(
                  i => i.mainQuestionId === q.questionId,
                );
                if (index !== -1) {
                  let newArray = [...this.ifElseSubQuestions];
                  newArray[index] = sq;
                  this.ifElseSubQuestions = newArray;
                } else {
                  this.ifElseSubQuestions.push(sq);
                }
              } else {
                this.ifElseSubQuestions = this.ifElseSubQuestions.filter(
                  i => i.mainQuestionId !== q.questionId,
                );
              }
              const someQuestionId = this.selected.filter(
                item => item.questionId === q.questionId,
              )[0];
              const foundItem = this.surveyDetail.questions.filter(
                item => item.questionId === q.questionId,
              );
              foundItem[0].answerSubmitted = true;
              if (!someQuestionId) {
                selectedOption = { questionId: q.questionId, optionId };
                this.selected.push({ ...selectedOption });
              } else {
                this.selected = this.selected.filter(item => {
                  if (item.questionId === q.questionId && !item.ifElseStyleSubQuestionAnswer) {
                    item.optionId = optionId;
                  }
                  return item;
                });
              }
            } else {
              const someQuestionId = this.selected.filter(
                item => item.questionId === q.questionId,
              )[0];
              const foundItem = this.surveyDetail.questions.filter(
                item => item.questionId === q.questionId,
              );
              foundItem[0].answerSubmitted = true;
              if (!someQuestionId) {
                selectedOption = { questionId: q.questionId, optionId };
                this.selected.push({ ...selectedOption });
              } else {
                this.selected = this.selected.filter(item => {
                  if (item.questionId === q.questionId) {
                    item.optionId = optionId;
                  }
                  return item;
                });
              }
            }
          } else {
            if (q.questionStyle === 4) {
              const someOptionId = this.selected.filter(
                item => item.ifElseStyleSubQuestionAnswer?.subQuestionId === subQuestionId,
              )[0];
              const foundItem = this.ifElseSubQuestions.filter(
                item => item.questionId === subQuestionId,
              );
              foundItem[0].answerSubmitted = true;
              if (!someOptionId) {
                selectedOption = {
                  questionId: q.questionId,
                  ifElseStyleSubQuestionAnswer: { subQuestionId, options: [optionId] },
                };
                this.selected.push({ ...selectedOption });
              } else {
                this.selected = this.selected.filter(item => {
                  if (item.ifElseStyleSubQuestionAnswer?.subQuestionId === subQuestionId) {
                    item.ifElseStyleSubQuestionAnswer.options = [optionId];
                  }
                  return item;
                });
              }
            } else {
              const someOptionId = this.selected.filter(
                item => item.evulationStyleSubQuestionAnswer?.subQuestionId === subQuestionId,
              )[0];
              const foundItem = this.subQuestions.filter(item => item.questionId === subQuestionId);
              foundItem[0].answerSubmitted = true;
              if (!someOptionId) {
                selectedOption = {
                  questionId: q.questionId,
                  evulationStyleSubQuestionAnswer: { subQuestionId, options: [optionId] },
                };
                this.selected.push({ ...selectedOption });
              } else {
                this.selected = this.selected.filter(item => {
                  if (item.evulationStyleSubQuestionAnswer?.subQuestionId === subQuestionId) {
                    item.evulationStyleSubQuestionAnswer.options = [optionId];
                  }
                  return item;
                });
              }
            }
          }
        }
      } else {
        let openText = event.currentTarget.value;
        if (q.questionStyle === 4) {
          const someOptionId = this.selected.filter(
            item => item.ifElseStyleSubQuestionAnswer?.subQuestionId === subQuestionId,
          )[0];
          const foundItem = this.ifElseSubQuestions.filter(
            item => item.questionId === subQuestionId,
          );
          foundItem[0].answerSubmitted = true;
          if (!someOptionId) {
            selectedOption = {
              questionId: q.questionId,
              ifElseStyleSubQuestionAnswer: { subQuestionId, openText },
            };
            this.selected.push({ ...selectedOption });
          } else {
            this.selected = this.selected.filter(item => {
              if (item.ifElseStyleSubQuestionAnswer?.subQuestionId === subQuestionId) {
                item.ifElseStyleSubQuestionAnswer.openText = openText;
              }
              return item;
            });
          }
        } else if (q.questionStyle === 2) {
          const someOptionId = this.selected.filter(
            item => item.evulationStyleSubQuestionAnswer?.subQuestionId === subQuestionId,
          )[0];
          const foundItem = this.subQuestions.filter(item => item.questionId === subQuestionId);
          foundItem[0].answerSubmitted = true;
          if (!someOptionId) {
            selectedOption = {
              questionId: q.questionId,
              evulationStyleSubQuestionAnswer: { subQuestionId, openText },
            };
            this.selected.push({ ...selectedOption });
          } else {
            this.selected = this.selected.filter(item => {
              if (item.evulationStyleSubQuestionAnswer?.subQuestionId === subQuestionId) {
                item.evulationStyleSubQuestionAnswer.openText = openText;
              }
              return item;
            });
          }
        } else if (q.questionStyle === 1 || q.questionStyle === 5) {
          const foundIndex = this.selected.findIndex(item => item.questionId === q.questionId);
          const foundItem = this.surveyDetail.questions.filter(
            item => item.questionId === q.questionId,
          );
          foundItem[0].answerSubmitted = true;
          if (foundIndex === -1) {
            selectedOption = { questionId: q.questionId, openText };
            this.selected.push({ ...selectedOption });
          } else {
            if (q.hasOtherOption) {
              currentOptions?.forEach(f => {
                if (f?.option == 'Diğer') {
                  otherOptionId = f.optionId;
                }
              });
              this.selected = this.selected.filter(f => {
                if (f.optionId === otherOptionId) {
                  f.openText = element.value;
                } else {
                  f = { optionId, questionId: q.questionId };
                }
                return f;
              });
            } else {
              this.selected[foundIndex] = { optionId, questionId: q.questionId };
            }
          }
        } else {
          const foundIndex = this.selected.findIndex(
            item => item.questionId === q.questionId && !item.optionId,
          );
          const foundItem = this.ifElseSubQuestions.filter(
            item => item.questionId === subQuestionId,
          );
          foundItem[0].answerSubmitted = true;
          if (foundIndex === -1) {
            const foundItem = this.surveyDetail.questions.filter(
              item => item.questionId === q.questionId,
            );
            foundItem[0].answerSubmitted = true;
            selectedOption = { questionId: q.questionId, openText };
            this.selected.push({ ...selectedOption });
          } else {
            if (q.hasOtherOption) return;
            this.selected[foundIndex].openText = openText;
          }
        }
      }
      const foundIndex = this.selected.findIndex(item => item.questionId === q.questionId);
      if (q.hasOtherOption) {
        if (!q.isMultiChoise) {
          if (currentOptions[0]?.option != 'Diğer') {
            this.selected[foundIndex] = { optionId, questionId: q.questionId };
          }
        }
      }

      this.setOtherOptionQuestions(opt, q);
      this.formModel = {
        surveyId: this.surveyDetail.surveyId,
        surveyTakenId: this.surveyDetail.surveyTakenId,
        answers: this.selected,
      };
      this.validQuestions();
    },

    setOtherOptionQuestions(opt, q) {
      if (!opt) return;
      let element = document.getElementById('option' + q.index);
      const hasOtherOption =
        this.selected.map(m => m.optionId).includes(opt?.optionId) && opt?.option == 'Diğer';

      if (opt?.option == 'Diğer' && q.hasOtherOption) {
        element.removeAttribute('hidden');

        if (!hasOtherOption) {
          this.clearOtherOptionFreeText(element);
        }
      } else {
        if (q.isMultiChoise) {
          if (!hasOtherOption) return;
          this.clearOtherOptionFreeText(element);
        } else {
          if (hasOtherOption) return;
          this.clearOtherOptionFreeText(element);
        }
      }
    },
    clearOtherOptionFreeText(element) {
      element.setAttribute('hidden', 'hidden');
      element.value = '';
    },
    selectVote(vote, q) {
      let c = this.subQuestions.find(i => i.mainQuestionId === q.questionId && i.answerSubmitted);
      if (c) {
        this.selected = this.selected.filter(
          i => i.evulationStyleSubQuestionAnswer?.subQuestionId !== c.questionId,
        );
        c.answerSubmitted = false;
      }
      let minOrange = q.evulationQuestionDetail.subQuestions[1].minPoint;
      let minGreen = q.evulationQuestionDetail.subQuestions[2].minPoint;
      if (q.evulationQuestionDetail.subQuestions[0].subQuestion) {
        let sq;
        if (vote < minOrange) {
          sq = q.evulationQuestionDetail.subQuestions[0].subQuestion;
        } else if (vote < minGreen) {
          sq = q.evulationQuestionDetail.subQuestions[1].subQuestion;
        } else {
          sq = q.evulationQuestionDetail.subQuestions[2].subQuestion;
        }
        if (sq) {
          sq.answerSubmitted = false;
          let index = this.subQuestions.findIndex(i => i.mainQuestionId === q.questionId);
          if (index !== -1) {
            let newArray = [...this.subQuestions];
            newArray[index] = sq;
            this.subQuestions = newArray;
          } else {
            this.subQuestions.push(sq);
          }
        } else {
          this.subQuestions = this.subQuestions.filter(i => i.mainQuestionId !== q.questionId);
        }
      }
      let selectedOption;
      const foundIndex = this.selected.findIndex(
        item => item.questionId === q.questionId && !item.optionId,
      );
      const foundItem = this.surveyDetail.questions.filter(
        item => item.questionId === q.questionId,
      );
      if (foundIndex === -1) {
        selectedOption = {
          questionId: q.questionId,
          point: vote,
        };
        foundItem[0].answerSubmitted = true;
        this.selected.push({ ...selectedOption });
      } else {
        let n = [...this.selected];
        n[foundIndex].point = vote;
        this.selected = n;
        foundItem[0].answerSubmitted = true;
      }
      this.formModel = {
        surveyId: this.surveyDetail.surveyId,
        surveyTakenId: this.surveyDetail.surveyTakenId,
        answers: this.selected,
      };
      this.validQuestions();
    },
    selectDateAndTime(e, questionId, type, isDateAndTime, q) {
      let selectedOption;
      const foundIndex = this.selected.findIndex(
        item => item.questionId === questionId && !item.optionId,
      );
      const foundItem = this.surveyDetail.questions.filter(item => item.questionId === questionId);
      if (foundIndex === -1) {
        selectedOption = {
          questionId,
          [type]: type === 'date' ? this.dateFormatter(e, '/') : e.displayTime,
        };
        foundItem[0].answerSubmitted = true;
        if (isDateAndTime) {
          foundItem[0].answerSubmitted = false;
        }
        this.selected.push({ ...selectedOption });
      } else {
        if (type === 'date') {
          this.selected[foundIndex].date = this.dateFormatter(e, '/');
        } else {
          this.selected[foundIndex].hour = e.displayTime;
        }
        foundItem[0].answerSubmitted = true;
        const si = this.selected.find(item => item.questionId === questionId);
        if (isDateAndTime && Object.keys(si).length !== 3) {
          foundItem[0].answerSubmitted = false;
        } else if (q.isRequired) {
          foundItem[0].answerSubmitted = true;
        }
      }
      this.formModel = {
        surveyId: this.surveyDetail.surveyId,
        surveyTakenId: this.surveyDetail.surveyTakenId,
        answers: this.selected,
      };
      this.validQuestions();
    },
    filteredVote(q) {
      let maxVote = q.evulationQuestionDetail.subQuestions[2]?.maxPoint;
      let minOrange = q.evulationQuestionDetail.subQuestions[1]?.minPoint;
      let minGreen = q.evulationQuestionDetail.subQuestions[2]?.minPoint;
      let array = [];
      let i = 0;
      for (i; i <= maxVote; i++) {
        let voteClass;
        if (i < minOrange) {
          voteClass = 'red';
        } else if (i < minGreen) {
          voteClass = 'orange';
        } else {
          voteClass = 'green';
        }
        array.push({
          value: i,
          voteClass: voteClass,
        });
      }
      return array;
    },
    dateFormatter(date, separator = '-', reverse = false) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      if (reverse) {
        return [year, month, day].join(separator);
      } else {
        return [day, month, year].join(separator);
      }
    },
    isVoteActive(i) {
      return this.point === i;
    },
    pointVote(vote, id) {
      let selectedAll = document.querySelectorAll(`.vote-item-${id}`);
      selectedAll.forEach(item => {
        item.classList.remove('active');
      });
      let selected = document.querySelector(`[data-id="vote-${vote}-${id}"]`);
      selected.classList.add('active');
    },
    goToHome() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`);
    },
    optionClass(optionId, isSubQuestion, isIfElseQuestion) {
      let opStyle = '';
      if (!isSubQuestion) {
        this.selected.map(item => {
          if (item.optionId === optionId) {
            opStyle = 'selected-option';
          }
          this.rightOptions.map(op => {
            if (op.optionId === optionId) {
              opStyle = 'correct-answer';
            } else {
              if (item.optionId === optionId && op.optionId !== item.optionId) {
                opStyle = 'wrong-answer';
              }
            }
          });
        });
      } else {
        if (isIfElseQuestion) {
          this.selected.map(item => {
            if (item.ifElseStyleSubQuestionAnswer?.options?.includes(optionId)) {
              opStyle = 'selected-option';
            }
            this.rightOptions.map(op => {
              if (op.ifElseStyleSubQuestionAnswer?.options?.includes(optionId)) {
                opStyle = 'correct-answer';
              } else {
                if (
                  item.ifElseStyleSubQuestionAnswer?.options?.includes(optionId) &&
                  !item.ifElseStyleSubQuestionAnswer?.options?.includes(optionId)
                ) {
                  opStyle = 'wrong-answer';
                }
              }
            });
          });
        } else {
          this.selected.map(item => {
            if (item.evulationStyleSubQuestionAnswer?.options?.includes(optionId)) {
              opStyle = 'selected-option';
            }
            this.rightOptions.map(op => {
              if (op.evulationStyleSubQuestionAnswer?.options?.includes(optionId)) {
                opStyle = 'correct-answer';
              } else {
                if (
                  item.evulationStyleSubQuestionAnswer?.options?.includes(optionId) &&
                  !item.evulationStyleSubQuestionAnswer?.options?.includes(optionId)
                ) {
                  opStyle = 'wrong-answer';
                }
              }
            });
          });
        }
      }
      return opStyle;
    },
    checkAnswerStatus(answerSubmitted) {
      return answerSubmitted === true ? true : false;
    },
    validQuestions() {
      const requiredQuestins = this.surveyDetail.questions.filter(f => !!f.isRequired);
      this.validate = requiredQuestins.every(i => !!i.answerSubmitted) && this.selected.length > 0;
    },
    saveAnswers() {
      GameApi.saveSurvey({ ...this.formModel }).then(res => {
        this.rightOptions = res.data.Data.rightOptions;
        this.surveyFinished = true;
        if (this.surveyFinished === true) {
          this.finishSurvey();
        }
      });
    },
    finishSurvey() {
      GameApi.playWinFinishSurvey(this.surveyDetail.surveyTakenId).then(res => {
        const successful = res.data.Data.successful;
        this.finish = true;
        if (this.surveyDetail.surveyType === 2) {
          const successModal = {
            isModalOpened: true,
            modalText: this.surveyDetail.afterSurveyHtml
              ? this.surveyDetail.afterSurveyHtml
              : 'Anket başarıyla tamamlanmıştır.',
            modalType: 'surveyAlert',
            alertType: 'apply',
            firstButtonText: 'TAMAM',
            surveyType: 2,
          };
          this.$store.dispatch('app/setModalConfig', successModal);
        } else {
          if (successful) {
            const successModal = {
              isModalOpened: true,
              modalText: 'Test başarıyla tamamlanmıştır.',
              modalType: 'surveyAlert',
              alertType: 'apply',
              firstButtonText: 'CEVAPLARI GÖR',
              surveyType: 1,
            };
            this.$store.dispatch('app/setModalConfig', successModal);
          } else {
            const failedModal = {
              isModalOpened: true,
              modalText: 'Yeterli doğru cevap sayısına ulaşamadınız.',
              modalType: 'surveyAlert',
              alertType: 'error',
              firstButtonText: 'CEVAPLARI GÖR',
              surveyType: 1,
            };
            this.$store.dispatch('app/setModalConfig', failedModal);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.take-survey-wrapper {
  .survey-title {
    width: 100%;
    line-height: palette-space-level('80');
    height: palette-space-level('80');
    background-image: linear-gradient(94deg, #602ee5, #3b37de 100%);
    margin-bottom: palette-space-level(60);
    font-size: palette-font-size-level('9');
    font-weight: palette-font-weight-level('3');
    color: palette-color-level('white', 100);
  }
  .questions-wrapper {
    margin-bottom: palette-space-level(40);
    .dropdownNumericPriceTotalContainer {
      margin-top: palette-space-level(40);
      margin-bottom: -1 * palette-space-level(20);
      display: flex;
      justify-content: center;
      .dropdownNumericPriceTotal {
        background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
        padding: palette-space-level(10) palette-space-level(10);
        border-radius: 24px;
      }
    }
    .question-container {
      margin-bottom: palette-space-level(20);
      &:last-child {
        margin-bottom: palette-space-level(10);
      }
      &-question {
        margin: palette-space-level(20) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dropdownNumericUnitPrice {
          padding: palette-space-level(10) palette-space-level(10);
          background: linear-gradient(222.39deg, #9a22a5 22.53%, #9c51d7 79.53%);
          border-radius: 24px;
          display: inline-block;
        }
      }
      &-options {
        .dropdownPrice {
          margin-top: palette-space-level(10);
          text-align: center;
        }
        .option-dropdown {
          select {
            width: 100%;
            padding: $button-padding;
            cursor: pointer;
            min-width: $button-min-width;
            font-family: $button-font-family;
            font-size: $button-font-size;
            font-weight: $button-font-weight;
            border-radius: $button-border-radius;
            box-shadow: $button-shadow;
            height: $button-height;
            -webkit-tap-highlight-color: transparent;
            user-select: none;
            outline: none;
          }
        }
        .option-list {
          textarea {
            &.option {
              border: 0.5px solid palette-color('grey-30');
              border-radius: 4px;
              width: 100%;
              padding: 20px;
              color: #23303d;
              font-family: 'Roboto', sans-serif;
            }
          }

          .option {
            border: 0.5px solid palette-color('grey-30');
            margin-bottom: palette-space-level(10);
            font-size: palette-font-size-level(6);
            font-weight: palette-font-weight-level(3);
            text-align: left;
            word-break: break-word;
          }
        }
        .selected-option {
          background-color: palette-color-level('grey', 30) !important;
          color: palette-color-level('white', 100) !important;
        }
        .correct-answer {
          background-color: palette-color-level('green', 10) !important;
          color: palette-color-level('white', 100) !important;
          opacity: 1 !important;
          &:before {
            display: none;
          }
        }
        .wrong-answer {
          background-color: palette-color-level('red', 10) !important;
          color: palette-color-level('white', 100) !important;
          opacity: 1 !important;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 600px;
  padding: palette-space-level('50') 0;
  margin: auto;
  img {
    margin-bottom: palette-space-level('30');
  }
  .submit-button {
    width: 320px !important;
    font-size: palette-font-size-level('5');
    font-weight: palette-font-weight-level('3');
    margin: palette-space-level('40') auto;
  }
}
.picker-wrapper {
  display: flex;
  align-items: center;
}
.time-picker {
  text-align: right;
  position: relative;
  &::before {
    content: 'Saat';
    position: absolute;
    top: 10px;
    left: 24px;
    color: #303030;
    font-weight: 300;
    z-index: 3;
  }
}
.vue__time-picker {
  /deep/ input {
    border: none;
    height: auto;
    font-size: 22px;
    width: 160px;
    height: 80px;
    padding: 24px 20px 0;
    border: 1px solid #23303d;
    border-radius: 4px;
    color: #23303d;
    &::placeholder {
      color: #303030 !important;
    }
  }
}

div.dropdown.drop-down > div > ul.minutes > li.active {
  background: '#e47b49';
}
/deep/ .time-picker-dropdown ul li,
.time-picker .dropdown ul li.active:hover {
  background: '#e47b49' !important;
}
ul.question-vote {
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    float: left;
    a {
      display: inline-block;
      width: 39px;
      height: 39px;
      line-height: 39px;
      border-radius: 50px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
      &.red {
        border: solid 2px #e02020;
        color: #e02020;
      }
      &.orange {
        border: solid 2px #fa6400;
        color: #fa6400;
      }
      &.green {
        border: solid 2px #6dd400;
        color: #6dd400;
      }
    }
  }
  li.active {
    float: left;
    a {
      display: inline-block;
      width: 39px;
      height: 39px;
      line-height: 39px;
      border-radius: 50px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      margin-left: 10px;
      cursor: pointer;
      &.red {
        background: #e02020;
        border: solid 2px #e02020;
        color: white;
      }
      &.orange {
        background: #fa6400;
        border: solid 2px #fa6400;
        color: white;
      }
      &.green {
        background: #6dd400;
        border: solid 2px #6dd400;
        color: white;
      }
    }
    .no-interview-item {
      border: solid 1px #23303d;
      background: #23303d;
      color: white;
    }
  }
  .no-interview {
    a {
      color: #79838e;
      border: 2px solid #79838e;
    }
    span {
      margin-top: 10px;
      display: block;
      color: #79838e;
      max-width: 100px;
      width: 100%;
    }
  }
}
.question-container-media {
  margin-bottom: 20px;
}
</style>
<style lang="scss">
.vue__time-picker-dropdown,
.vue__time-picker .dropdown {
  top: 70px;
}
.date-wrapper {
  margin-right: 20px;
  &::before {
    content: 'Tarih';
    position: absolute;
    top: 10px;
    left: 24px;
    color: #303030;
    font-weight: 300;
    z-index: 3;
  }
}
.date-input {
  font-size: 22px !important;
  color: #303030;
  height: 80px;
  width: 300px;
  padding: 24px 20px 0;
  position: relative;
  &::placeholder {
    font-size: 22px;
    color: #303030;
  }
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: linear-gradient(315deg, #d20051, #ffa300);
}
.vdp-datepicker__calendar .cell.selected {
  background: linear-gradient(315deg, #d20051, #ffa300) !important;
  color: #fff;
}
</style>
